import { Component, createEffect, createSignal, For, lazy, Show, Suspense } from "solid-js";
import { GameType } from "../Games/baseGame/objects";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { DialogTriggerProps } from '@kobalte/core/dialog';
import { Button } from "@/components/ui/button";
import { Settings } from 'lucide-solid';
import { ApiLoadInfo, ApiState } from "@polyomino/ts-utils/src/lib/types";
import { toaster } from "@kobalte/core";
import {
  Toast,
  ToastContent,
  ToastDescription,
  ToastProgress,
  ToastTitle
} from "@/components/ui/toast";
import { newGameService } from "@/service/service";
import { useNavigate } from "@solidjs/router";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DropdownMenuSubTriggerProps } from "@kobalte/core/dropdown-menu";
import { GameNameMap } from "../Games/baseGame/constants";
import { Loader2 } from "lucide-solid"
import stringify from "json-stringify-pretty-compact";


export type NewGameButtonProps = {
  schema: any
  defaultSettings: {games: {[key in GameType]: any}, default: GameType}
}

const LazyMonacoEditor = lazy(() =>
  import('solid-monaco').then((module) => ({ default: module.MonacoEditor }))
)


export const NewGameButton: Component<NewGameButtonProps> = (props) => {
  const schema = () => props.schema
  const defaultSettings = () => props.defaultSettings
  
  /* Variables */
  const navigate = useNavigate();
  const games = [
    {type: GameType.polyssimo, name: GameNameMap[GameType.polyssimo]},
    {type: GameType.isle_of_cats, name: GameNameMap[GameType.isle_of_cats]},
    {type: GameType.barenpark, name: GameNameMap[GameType.barenpark]}
  ]

  const [showConfigEditor, setShowConfigEditor] = createSignal<boolean>(false)
  const [monacoRef, setMonacoRef] = createSignal<any>()
  const [newGameLoadInfo, setNewGameLoadInfo] = createSignal<ApiLoadInfo>({state: ApiState.NOT_LOADED})
  const [settingsStr, setSettingsStr] = createSignal<string>("")

  /* Utils */
  const jsonPretty = (obj: any) => {
    return JSON.stringify(obj, null, 2)
    return stringify(obj, {maxLength: 100})
  }

  const setJsonSchemaToEditor = () => {
    if (!monacoRef()) {
      return
    }

    setSettingsStr(jsonPretty(defaultSettings().games[defaultSettings().default]))

    monacoRef().languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: "http://game/schema.json", // id of the first schema
          fileMatch: ["*"], // associate with our model
          schema: schema()
        },
      ]
    });
  }

  /* UI events */
  const onEditorMount = (monaco, editor) => {
    setMonacoRef(monaco)
  }

  const loadPreset = (gameType: GameType) => {
    setSettingsStr(jsonPretty(defaultSettings().games[gameType]))
  }

  const startNewGame = async () => {
    let settings = null
    try {
      settings = JSON.parse(settingsStr())
    }
    catch (error) {
      toaster.show(props => (
        <Toast toastId={props.toastId}>
          <ToastContent>
            <ToastTitle>Invalid JSON</ToastTitle>
            <ToastDescription>{error.toString()}</ToastDescription>
          </ToastContent>
          <ToastProgress />
        </Toast>
      ))
      throw error
    }

    setNewGameLoadInfo({state: ApiState.LOADING})
    const { response, error } = await newGameService({settings})
      if (error) {
        console.error(error)
        setNewGameLoadInfo({ state: ApiState.ERROR, error })
        toaster.show(props => (
          <Toast toastId={props.toastId}>
            <ToastContent>
              <ToastTitle>{error.message}</ToastTitle>
              <Show when={!!error.stack_trace}>
                <ToastDescription>{error.stack_trace}</ToastDescription>
              </Show>
            </ToastContent>
            <ToastProgress />
          </Toast>
        ))
        throw error
      }
      navigate(`/game/${response.game.id}`)
  }

  // Reactive events
  createEffect(() => {
    setJsonSchemaToEditor()
  })

  return <>
    <Tooltip>
      <TooltipTrigger>
      <Button variant="ghost" size='icon' onClick={() => setShowConfigEditor(true)}>
        <Settings class='text-white hover:text-black' />
      </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>New game from config</p>
      </TooltipContent>
    </Tooltip>


    {/* Config editor */}
    <Dialog open={showConfigEditor()} onOpenChange={setShowConfigEditor}>
      <DialogContent class="min-w-fit">
        <div class='flex flex-col gap-4' style="height: 90vh; min-height: 100px; width: 60vw; min-width: 200px;">
          <div class="flex justify-between pr-10">
            <Button disabled={newGameLoadInfo().state === ApiState.LOADING || !monacoRef()} variant="default" onClick={startNewGame}>
              <Show when={newGameLoadInfo().state === ApiState.LOADING}>
                <Loader2 class="mr-2 h-4 w-4 animate-spin" />
              </Show>
              New game
            </Button>
            <div>
              <DropdownMenu placement="bottom">
                <DropdownMenuTrigger
                  as={(props: DropdownMenuSubTriggerProps) => (
                    <Button variant="outline" {...props}>
                      Presets
                    </Button>
                  )}
                />
                <DropdownMenuContent class="w-56">
                  <For each={games}>{(game, i) => (
                    <DropdownMenuItem class="cursor-pointer" onClick={() => loadPreset(game.type)}>{game.name}</DropdownMenuItem>
                  )}
                  </For>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <div class='grow'>
          <Suspense fallback={<div>Loading editor...</div>}>
            <LazyMonacoEditor 
                class="h-full w-full" 
                language="json" 
                value={settingsStr()} 
                onChange={setSettingsStr} 
                onMount={onEditorMount}
            />;
          </Suspense>
          </div>        
        </div>
      </DialogContent>
    </Dialog>
</>
}