import { batch, Component, createMemo, createSignal, JSX, onCleanup, onMount, Show } from "solid-js"
import './Barenpark.scss';
import { Pool } from "@/components/Pool/Pool";
import { Game, Piece, PiecePlacement, Player, Board as BoardType, ExtensionPlacement, ResourceType } from "./objects";
import { Board } from "@/components/Board/Board";
import { ExtendGridRequest, MakeMoveRequest, PassMoveRequest, PossibilitiesRequest, Request, RequestType } from "./request";
import { Response, ResponseType } from "./response";
import { GameProps } from "../baseGame/componentBase";
import { PlayerType, Position, Possibility } from "../baseGame/objects";
import { NewGameButton } from "@/components/NewGameButton/NewGameButton";
import { Button } from "@/components/ui/button";
import { BOARD_STYLE, getExtensionStyle, getPieceStyle, getPossibilityStyle, getResourceStyle, getRoomStyle } from "../baseGame/constants";
import { Tile } from "@/components/Tile/Tile";
import { deepClone, isNullOrUndefined } from "@polyomino/ts-utils/src/lib/utils";

export type BarenparkProps = GameProps<Game, Request, Response>


export const Barenpark: Component<BarenparkProps> = (props) => {
  /*********
  * Inputs *
  **********/
  const schema = () => props.schema
  const defaultSettings = () => props.defaultSettings
  const sendMessage = props.sendMessage

  /************
  * Variables *
  *************/
  const [playerContainerRef, setPlayerContainerRef] = createSignal<HTMLElement>()
  const [game, setGame] = createSignal<Game>(props.initGame)
  const [maxHeight, setMaxHeight] = createSignal(window.innerHeight/2)
  const [maxWidth, setMaxWidth] = createSignal(window.innerWidth/2)

  /*********************
  * Computed variables *
  **********************/
  const isGameEnded = createMemo<boolean>(() => {
    return game().ended
  })

  const currentPlayer = createMemo<Player>(() => {
    return game().players.find((x) => x.id === game().current_turn)
  })

  const isMyTurn = createMemo<boolean>(() => {
    return !isGameEnded() && currentPlayer().type === PlayerType.human
  })

  const isNextAddonBoardGrizzly = () => {
    return currentPlayer().parks.length === 4
  }


  /**********
  * Helpers *
  ***********/
  const poolJsx = () => {
    // sort by custom order by resource
    // sort by shape name
    // sort by allowed first
    // sort by max score first
    // Keep only 1st option for each shape name
    
    const sortValue = (aOrder: number, bOrder: number, asc=true) => {
      if (!asc) {
        [aOrder, bOrder] = [bOrder, aOrder]
      }
      return aOrder === bOrder ? 0 : (aOrder < bOrder ? -1 : 1)
    }

    let poolPieces = game().pool.pieces


    // sort by max score
    poolPieces.sort((a, b) => {
      const _x = (x: Piece) => {
        return isNullOrUndefined(x.score) ? -1 : x.score
      }

      const aOrder = _x(a)
      const bOrder = _x(b)
      return sortValue(aOrder, bOrder, false)
    })

     // sort by allowed
     poolPieces.sort((a, b) => {
      const _x = (x: Piece) => {
        return currentPlayer().disabled_pieces.includes(x.id) ? -1 : 1
      }

      const aOrder = _x(a)
      const bOrder = _x(b)
      return sortValue(aOrder, bOrder, false)
    })

    // sort by shape name
    poolPieces.sort((a, b) => {
      const aOrder = +a.shape.name.match(/\d+$/)[0]
      const bOrder = +b.shape.name.match(/\d+$/)[0]
      return sortValue(aOrder, bOrder)
    })

    // sort by custom order by resource 
    poolPieces.sort((a, b) => {
      const _x = (x: Piece) => {
        const mapper = {
          [ResourceType.wheelbarrow]: 1,
          [ResourceType.cement_truck]: 2,
          [ResourceType.excavator]: 3,
          [ResourceType.grizzly]: 4,
        }
        return mapper[x.resource] || -1
      }

      const aOrder = _x(a)
      const bOrder = _x(b)
      return sortValue(aOrder, bOrder)
    })

    // Keep only 1st option for each shape name
    const firstShape = {}
    for (let piece of poolPieces) {
      if (!firstShape[piece.shape.name]) {
        firstShape[piece.shape.name] = piece.id
      }
    }
    poolPieces = poolPieces.filter((x) => x.id === firstShape[x.shape.name])

    const poolPiecesWithTiles = poolPieces.map((piece) => {
      return {
        ...piece,
        tile: <Tile grid={piece.shape.grid} style={getPieceStyle(piece.color)} />,
        heading: <div class="text-sm">{piece.shape.name} {piece.score ? `(${piece.score})`: ''}</div>
      }
    })

    // Add boards
    const boardPileIdMap = {}
    for (let poolBoard of game().pool.boards) {
      if (isNullOrUndefined(boardPileIdMap[poolBoard.pile_id])) {
        boardPileIdMap[poolBoard.pile_id] = poolBoard
      }
    }
    const boardsToShow = Object.keys(boardPileIdMap).sort().map((x) => boardPileIdMap[x])

    const boardsWithTiles = boardsToShow.map((board) => {
      const resourcePlacements = board.resource_placements.map((placement) => {
        return {
          position: placement.position,
          tile: <Tile grid={placement.resource.shape.grid} style={placement.resource.type === ResourceType.pit ? getPieceStyle(placement.resource.color) : getResourceStyle(placement.resource.color)} />,
        }
      })
      
      const tiles = resourcePlacements

      return {
        id: board.id,
        shape: board.shape,
        tile: <Board
          grid={board.shape.grid}
          style={BOARD_STYLE}
          showBorder={false}
          maxWidth={18 * 4}
          minCellSize={16}
          tiles={tiles}
        ></Board>,
        heading: <div class="text-sm">{board.name}</div>
      }
    })

    const poolProps = {
      ...game().pool, pieces: poolPiecesWithTiles.concat(boardsWithTiles as any)
    }

    return (
      <div class="flex justify-center" style="min-width: 600px;">
        <div class='flex p-4 pt-1 max-h-64 overflow-auto'>
          <Pool class="" disabled={!isMyTurn()} orientation='horizontal' pool={poolProps} disabledPieces={currentPlayer().disabled_pieces.concat(currentPlayer().disabled_boards)} selectedPiece={selectedPiece()} onPieceSelect={onPoolPieceSelection} />
        </div>
      </div>
    )
  }

  const playerLayoutJsx = (playerId: string) => {
    const player = game().players.find((x) => x.id === playerId)

    const parkTiles = player.parks.map((park) => {
      return [{
        position: park.position,
        tile: <Tile grid={park.board.shape.grid} style={{color: "transparent", border: {color: "gray", width: 0.03}, borderRadius: 0.125}} />
      }]
    }).flat()

    const resourcePlacements = player.board.resource_placements.map((placement) => {
      return {
        position: placement.position,
        tile: <Tile grid={placement.resource.shape.grid} style={placement.resource.type === ResourceType.pit ? getPieceStyle(placement.resource.color) : getResourceStyle(placement.resource.color)} />,
      }
    })

    const pieceTiles = player.board.piece_placements.map((placement) => {
      return {
        position: placement.position,
        tile: <Tile grid={placement.transformed_piece.shape.grid} style={{...getPieceStyle(placement.piece.color)}} />
      }
    })

    const possibilityTiles = currentPlayer().id === player.id && selectedPiece() ? possibilities().map((possibility) => {
      return {
        position: possibility.position,
        tile: <Tile grid={[[1]]} style={{...getPossibilityStyle(selectedPiece().color, possibility.selected)}} />
      }
    }) : []

    let gridExtensionHighlights = []
    if (player.next_move_add_grid) {
      for (let placement of player.allowed_extension_placements) {
        gridExtensionHighlights.push({
          position: placement.possibility_position,
          tile: <Tile grid={placement.possibility_shape.grid} style={{...getExtensionStyle(placement.id === selectedGridPlacement()?.id)}} />
        })
      }
    }

    const tiles = parkTiles.concat(resourcePlacements).concat(pieceTiles).concat(possibilityTiles).concat(gridExtensionHighlights)

    return (
      <div class='flex flex-col text-neutral-content overflow-auto'>
        <div class={`text-center ${player.id === game().winner ? 'text-green-600' : player.id === currentPlayer().id && !isGameEnded() ? 'text-blue-600' : 'text-white'}`}>
          <div>
            {player.type === PlayerType.human ? 'Human' : 'AI'} ({player.score})
            <Show when={player.id === game().winner}>
              <span class='ml-1'>(Winner)</span>
            </Show>
          </div>
          <div class="flex justify-center gap-x-4">
            <div>[{player.score_breakup.join(', ')}]</div>
            <div>[{player.has_pieces.join(', ')}]</div>
          </div>
        </div>
        
        <div class='grow pt-4 flex justify-center'>
          <Board
            grid={player.board.shape.grid}
            style={BOARD_STYLE}
            maxWidth={maxWidth()}
            maxHeight={maxHeight()}
            tiles={tiles}
            onBoardCellSelect={(position) => onBoardCellSelect(player, position)}
          ></Board>
        </div>
      </div>
    )
  }

  /************************************ 
  * Piece selection and possibilities *
  *************************************/
  // variables
  const [selectedPiece, setSelectedPiece] = createSignal<Piece>(null);
  const [selectedPositions, setSelectedPositions] = createSignal<Position[]>([])
  const [possibilities, setPossibilities] = createSignal<Possibility[]>([])
  const [finalPlacement, setFinalPlacement] = createSignal<PiecePlacement | null>(null)

  const [selectedGrid, setSelectedGrid] = createSignal<BoardType>(null);
  const [selectedGridPlacement, setSelectedGridPlacement] = createSignal<ExtensionPlacement>(null);

  // helpers
  const resetSelectedPiece = () => {
    setSelectedPiece(null)
    setSelectedPositions([])
    setPossibilities([])
    setFinalPlacement(null)
  }

  const resetSelectedGrid = () => {
    setSelectedPiece(null)
    setSelectedGrid(null)
    setSelectedGridPlacement()
  }

  const triggerGetPossibilities = () => {
    const message: PossibilitiesRequest = {
      id: crypto.randomUUID(),
      type: RequestType.POSSIBILITIES,
      timestamp: Date.now(),
      data: {
        selected_piece: selectedPiece(),
        selected_positions: selectedPositions()
      }
    }
    sendMessage(message)
  }

  // events
  const onPoolPieceSelection = (piece: Piece) => {
    const boardIds = game().pool.boards.map((x) => x.id)
    if (boardIds.includes(piece.id)) {
      const board = game().pool.boards.find((x) => x.id == piece.id)
      setSelectedGrid(board)
      setSelectedPiece(piece)
      return
    }

    resetSelectedPiece()
    setSelectedPiece(piece)
    triggerGetPossibilities()
  }

  const onBoardCellSelect = (player: Player, position: Position) => {
    if (currentPlayer().id !== player.id) {
      return
    }

    if (currentPlayer().next_move_add_grid) {
      const placement = currentPlayer().allowed_extension_placements.find((allowed_placement) => 
        allowed_placement.possibility_positions.find((p) => p.x == position.x && p.y == position.y)
      )
      setSelectedGridPlacement(placement)
      console.log('position', position, 'placement', placement)
      return
    }

    if (!selectedPiece()) {
      return
    }
    if (!possibilities().find((possibility) => possibility.position.x === position.x && possibility.position.y === position.y)) {
      // chose a cell that is not among valid possibilities
      setSelectedPositions([])
    }
    else if (selectedPositions().find((pos) => pos.x === position.x && pos.y === position.y)) {
      // possibility already selected, unselect it
      setSelectedPositions(
        selectedPositions().filter((pos) => !(pos.x === position.x && pos.y === position.y))
      )
    }
    else {
      // new possibility selected
      setSelectedPositions(
        selectedPositions().concat([position])
      )
    }
    triggerGetPossibilities()
  }

  const resetTurn = () => {
    onPoolPieceSelection(selectedPiece())
  }

  /************ 
  * Make move *
  *************/
  // events
  const passMove = () => {
    const message: PassMoveRequest = {
      id: crypto.randomUUID(),
      type: RequestType.PASS_MOVE,
      timestamp: Date.now(),
      data: {piece: selectedPiece()}
    }
    sendMessage(message)
  }

  const extendGrid = () => {
    const message: ExtendGridRequest = {
      id: crypto.randomUUID(),
      type: RequestType.EXTEND_GRID,
      timestamp: Date.now(),
      data: {
        extended_board: selectedGrid(),
        placement: selectedGridPlacement()
      }
    }
    sendMessage(message)
  }

  const makeMove = () => {
    const message: MakeMoveRequest = {
      id: crypto.randomUUID(),
      type: RequestType.MAKE_MOVE,
      timestamp: Date.now(),
      data: {
        piece_placement: finalPlacement()
      }
    }
    sendMessage(message)
  }

  /*********
  UI Tiles *
  *********/


  /*******************
  * Response handler *
  ********************/
  const handleMessage = (message: Response) => {
    const { type, data } = message
    switch (type) {
      case ResponseType.INIT, ResponseType.GAME_STATE: {
        batch(() => {
          setGame(message.data.game)
          resetSelectedPiece()
          resetSelectedGrid()
        })
        break
      }
      case ResponseType.POSSIBILITIES: {
        batch(() => {
          setPossibilities(message.data.possibilities)
          setFinalPlacement(message.data.final_placement)
        })
        break
      }
    }
  }
  
  /*******
  * Init *
  ********/
  const initialOrder = game().players.map((x) => x.id)
  props.ref({
    handleMessage: handleMessage
  })

  onMount(() => {
    resizeHandler()
  })

  /* Setup resizer */
   const resizeHandler = () => {
    if (playerContainerRef()) {
      const [playerBoardAvailableWidthRatio, playerContainerGap, playerContainerPadding] = [0.9, 16, 8]
      const [playerContainerTextHeight, playerBoardPadding] = [48, 16]
      const playerContainerWidth = playerContainerRef().getBoundingClientRect().width * playerBoardAvailableWidthRatio
      const playerContainerHeight = playerContainerRef().getBoundingClientRect().height
      const width = (playerContainerWidth - (playerContainerGap + playerContainerPadding*2))/2
      const height = playerContainerHeight - playerContainerTextHeight - playerBoardPadding
      setMaxWidth(width)
      setMaxHeight(height)
    }
    else {
      setMaxWidth(window.innerWidth/2)
      setMaxWidth(window.innerHeight/2)
    }
  };
  window.addEventListener('resize', resizeHandler);
  resizeHandler();
  onCleanup(() => {
    window.removeEventListener('resize', resizeHandler);
  });

  /*****
  * UI *
  ******/
  return (
    <div class='flex flex-col flex-1 h-full items-center gap-4 pt-8 pb-32 xl:pt-0 xl:pb-0'>
      <div class='flex flex-col'>
        <div class="flex justify-between items-center gap-4 pt-3 pb-2 px-4">
          <Show when={schema()}>
            <div class="flex-none">
              <NewGameButton schema={schema()} defaultSettings={defaultSettings()} />
            </div>
          </Show>
          <div class="flex-grow text-center text-white">
            <div>{isGameEnded() ? '(Game over)': ''}</div>
          </div>
          <div class="flex gap-4 flex-none mr-2">
            {/* <Button
              variant="outline"
              disabled={!isMyTurn() || !currentPlayer().passed || !selectedPiece()}
              onClick={passMove}
            >
              Pass
            </Button> */}
            <Button
              variant="outline"
              disabled={!isMyTurn() || (currentPlayer().next_move_add_grid ? (!selectedGrid() || !selectedGridPlacement()) : !finalPlacement())}
              onClick={() => currentPlayer().next_move_add_grid ? extendGrid() : makeMove()}
            >
              Save
            </Button>
          </div>
        </div>

        {poolJsx()}
      </div>


      <div ref={setPlayerContainerRef} class="flex flex-wrap gap-4 px-2 justify-center w-full h-full">
        <div class="w-[45%]">
          {playerLayoutJsx(initialOrder[0])}
        </div>
        <div class="w-[45%]">
        {playerLayoutJsx(initialOrder[1])}
        </div>
      </div>
    </div>

  )
}